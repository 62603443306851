<template>
  <validation-observer ref="form">
    <validation-provider :rules="model._id? '' : 'required'" name="Stock file" v-slot="validation">
      <b-form-group>
        <label>Tuned file</label>
        <b-form-file class="drop-file-input" placeholder="Drop tuned file here or click to select."
                     v-model="model.tuned_file" @change="onFileChange"
                     :state="getState(validation)"/>
        <span class="text-danger">{{ validation.errors[0] }}</span>
      </b-form-group>
    </validation-provider>
    <b-form-group v-if="model._id && model.tuned_file">
      <label>New file comment</label>
      <b-form-input v-model="model.file_comment"/>
    </b-form-group>
    <validation-provider rules="required" name="stage" v-slot="validation">
      <b-form-group>
        <label>Stage</label>
        <b-form-select v-model="model.stage" :options="stages[stock_file.file_type]"
                       :state="getState(validation)" @change="onChangeStage">
          <b-form-select-option :value="null">Select a stage</b-form-select-option>
        </b-form-select>
        <span class="text-danger">{{ validation.errors[0] }}</span>
      </b-form-group>
    </validation-provider>
    <template v-if="stock_file.file_type === 'ECU'">
      <b-row>
        <b-col>
          <validation-provider rules="required" name="Newton-metre" v-slot="validation">
            <b-form-group>
              <label>Nm</label>
              <b-form-input v-model.number="model.nm" :state="getState(validation)"/>
              <span class="text-danger">{{ validation.errors[0] }}</span>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider rules="required" name="Host-power" v-slot="validation">
            <b-form-group>
              <label>Hp</label>
              <b-form-input v-model.number="model.hp" :state="getState(validation)"/>
              <span class="text-danger">{{ validation.errors[0] }}</span>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </template>
    <div>
      <div class="text-right">
        <b-button v-b-toggle.editor variant="secondary" size="sm">Hide/show options</b-button>
      </div>
      <b-collapse visible id="editor" class="px-3">
        <options-editor ref="editor" :cleanDSGAndECU="this.currentDsg" :vehicleDSG="this.stock_file" @input="setOptions"/>
      </b-collapse>
    </div>
    <validation-provider rules="required" v-slot="validation">
      <b-form-group>
        <label>Title</label>
        <b-form-input v-model="model.title" :state="getState(validation)"/>
        <span class="text-danger">{{ validation.errors[0] }}</span>
      </b-form-group>
    </validation-provider>
    <validation-provider rules="required" v-slot="validation">
      <b-form-group>
        <label>Description</label>
        <b-form-textarea v-model="model.description" :state="getState(validation)"/>
        <span class="text-danger">{{ validation.errors[0] }}</span>
      </b-form-group>
    </validation-provider>
    <b-form-group v-if="showIsHPP">
      <label>Require High Performance verification</label>
      <b-checkbox v-model="model.is_hpp">{{ model.is_hpp ? 'Yes' : 'No' }}</b-checkbox>
    </b-form-group>
    <b-row>
      <b-col>
        <b-form-group>
          <label>Active</label>
          <b-form-checkbox switch v-model="model.status"/>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <label>Recommended</label>
          <b-form-checkbox switch v-model="model.recommended"/>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="text-right">
      <b-button @click="save" size="lg" variant="primary">{{ model._id ? 'Update file' : 'Add file to database' }}
      </b-button>
    </div>
  </validation-observer>
</template>

<script>
import {post} from "@/services/api";
import OptionsEditor from "@/components/SoftwareOrders/OptionsEditor";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "FormTunedFile",
  components: {OptionsEditor},
  mixins: [show_alert_mixin],
  props: {
    dataToCompare: {
      required: false,
      type: Array,
      default: []
    },
    stock_file: Object,
  },
  data: () => ({
    currentDsg:false,
    model: {
      title: null,
      description: null,
      tuned_file: null,
      stage: null,
      options: [],
      visual_options: [],
      status: false,
      recommended: false,
      is_hpp: false,
      hp: '',
      nm: '',
    },
    stages: {
      ECU: [
        {text: 'Stage 1', value: '1', field: 'stageOnePrice'},
        {text: 'Stage 2', value: '2', field: 'stageTwoPrice'},
        {text: 'Stage 2+', value: '2.1', field: 'stageTwoPlusPrice'},
        {text: 'Stage 3', value: '3', field: 'stageThreePrice'},
        {text: 'Stage 4', value: '4', field: 'stageFourPrice'}
      ],
      TCU: [
        {text: 'Drivability', value: 'Stock', field: 'stockPrice'},
        {text: 'Stage 1', value: '1', field: 'stageOnePrice'},
        {text: 'Stage 2', value: '2', field: 'stageTwoPrice'},
        {text: 'Stage 2+', value: '2.1', field: 'stageTwoPlusPrice'},
        {text: 'Stage 3', value: '3', field: 'stageThreePrice'},
        {text: 'Stage 4', value: '4', field: 'stageFourPrice'}
      ],
    },
  }),
  methods: {
    setToEdit(param) {
      const model = JSON.parse(JSON.stringify(param));
      this.model = model;
      this.$nextTick(() => {
        setTimeout(async () => {
          this.$refs.editor.reset();
          await this.$refs.editor.loadVehicle(model.stock_file.vehicle_id);
          const params = {
            type: this.stock_file.file_type.replace('TCU', 'DSG'),
            id: model.stage
          };
          this.$refs.editor.setOptions(params, model.visual_options);
        }, 100);
      })
    },
    onFileChange(ev) {
      let file = null;
      if (ev instanceof DragEvent) {
        if (ev.dataTransfer.items) {
          file = ev.dataTransfer.items[0].getAsFile()
        } else {
          file = ev.dataTransfer.files[0]
        }
      } else {
        file = ev.target.files[0]
      }
      if (!this.model._id) {
        this.model.title = file.name;
      }
    },

    dataToCompareMethod(){
      let dataToCompareTunedFile =[ {
          title:this.model.title,
          option:this.model.options,
          stage:this.model.stage,
          hp:this.model.hp.toString(),
          nm:this.model.nm.toString(),
        }]
       return this.duplicatedStageAndOptions(dataToCompareTunedFile,this.dataToCompare)
    },

    duplicatedStageAndOptions(arr1, arr2) {
        for (let i = 0; i < arr1.length; i++) {
          const obj1 = arr1[i];

          for (let j = 0; j < arr2.length; j++) {
            const obj2 = arr2[j];
            if (obj1.stage === obj2.stage && obj1.hp === obj2.hp && obj1.nm === obj2.nm &&  JSON.stringify(obj1.option.sort()) === JSON.stringify(obj2.option.sort())) {
              return true;
            }
          }
        }
        return false;
      },

    save() {
      if(this.dataToCompareMethod() == true){
        this.showAlertNotification("There is already a file with same Stage and Options", "info");
      }else{
        if (!this.$refs.editor.validate())
          return;
        this.$refs.form.validate().then((success) => {
          if (success) {
            this.currentDsg = true
            this.stock_file.has_tuned_files = true
            const formData = new FormData()
            Object.keys(this.model).forEach(key => {
              formData.append(key, Array.isArray(this.model[key]) ? JSON.stringify(this.model[key]) : this.model[key])
            })
            this.$store.commit('loaderManager', true);
            let url = `files/upload-tuned-file/${this.stock_file._id}`;
            if (this.model._id) {
              url = `files/update-tuned-file/${this.model._id}`;
            }
            post(url, formData, true)
              .then(() => {
                this.showAlertNotification("Files uploaded successfully", "success");
                this.$emit('save');
                this.clearModel();
                this.$nextTick(() => {
                  this.$refs.form.reset();
                })
              }).finally(() => this.$store.commit('loaderManager', false))
          }
      })
      }
    },
    getState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null;

    },
    clearModel() {
      this.currentDsg = false
      this.model = {
        title: null,
        description: null,
        tuned_file: null,
        stage: null,
        options: [],
        visual_options: [],
        status: false,
        recommended: false,
        is_hpp: false,
        nm: '',
        hp: '',
      };
      this.$nextTick(() => {
        this.$refs.form.reset();
      })
    },
    setOptions(data) {
      this.model.options = data.map(option => {
        if (Array.isArray(option.options) && option.options.length) {
          return {[option.id]: option.options[0].id}
        }
        return option.id;
      });
      this.model.visual_options = data.map(option => ({
        id: option.id,
        option_id: option.option_id,
        name: option.name,
        description: option.description,
        location: option.location,
        value: Array.isArray(option.options) && option.options.length ? option.options[0].description : true,
        options: option.options,
        pre_included: option.required
      }))
    },
    async onChangeStage() {
      this.$refs.editor.reset();
      await this.$refs.editor.loadVehicle(this.stock_file.vehicle_id);
      const params = {
        type: this.stock_file.file_type.replace('TCU', 'DSG'),
        id: this.model.stage
      };
      this.$refs.editor.setParams(params);
    }
  },
  computed: {
    showIsHPP() {
      return (this.stock_file.file_type === 'TCU' && ['2.1', '3', '4'].includes(this.model.stage));
    },
  },
  mounted() {
    const checkDisabled = (stage) => {
      return !Boolean(this.stock_file.prices[stage.field])
    }

    this.stages[this.stock_file.file_type] = this.stages[this.stock_file.file_type].map(stage => ({
      ...stage,
      disabled: checkDisabled(stage)
    }));
  }
}
</script>

<style scoped>

</style>
