<template>
  <b-row class="p-4" v-if="detail">
    <b-col>
      <h4>
        <strong>
          {{ detail.stock_file.title }}
        </strong>
        -
        <b-badge :variant="detail.stock_file.is_supplying? 'success' : 'danger'">
          {{ detail.stock_file.is_supplying ? 'Enabled' : 'Disabled' }}
        </b-badge>
      </h4>
      <h4 class="mt-3">Project data</h4>
      <b-row>
        <b-col>
          <b-list-group>
            <b-list-group-item class="d-flex justify-content-between">
              <span>Tune type</span><span>{{ detail.stock_file.file_type }}</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between">
              <span>ECU Model</span><span>{{ detail.stock_file.dsg_family }}</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between">
              <span>flashtool</span><span>{{ detail.stock_file.flashtool }}</span>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col>
          <b-list-group>
            <b-list-group-item class="d-flex justify-content-between">
              <span>P/N</span><span>{{ detail.stock_file.hardware_number }}</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between">
              <span>Software version</span><span>{{ detail.stock_file.software_version }}</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between">
              <span>Created at</span><span>{{ detail.stock_file.created_at|localeDate }}</span>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <h6 class="mt-3">Vehicle</h6>
      <b-table small hover bordered fixed :items="[detail.vehicle]" :fields="['make','model','engine','generation']"/>
      <hr>
      <h4>Tune details</h4>
      <b-list-group>
        <b-list-group-item class="d-flex justify-content-between py-2">
          <span>Title</span><span>{{ detail.title }}</span>
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between py-2">
          <span>{{ detail.description }}</span>
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between py-2">
          <span>Stage</span><span>{{ detail.stage.replace('Stock', 'Drivability').replace('.1', '+') }}</span>
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between py-2">
          <span>Price</span><span>{{ detail.stock_file.prices[prices[detail.stage]]|euro }}</span>
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between py-2">
          <span>Created at</span><span>{{ detail.created_at|localeDate }}</span>
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between py-2">
          <span>Updated at</span><span>{{ detail.updated_at|localeDate }}</span>
        </b-list-group-item>
        <template v-if="detail.stock_file.file_type === 'ECU'">
          <b-list-group-item class="d-flex justify-content-between py-2">
            <span>HP</span><span>{{ detail.hp }}</span>
          </b-list-group-item>
          <b-list-group-item class="d-flex justify-content-between py-2">
            <span>Nm</span><span>{{ detail.nm }}</span>
          </b-list-group-item>
        </template>
        <b-list-group-item class="d-flex justify-content-between py-2">
          <span>Recommended?</span>
          <b-badge :variant="detail.recommended? 'success' : 'info'">{{ detail.recommended ? 'YES' : 'NO' }}</b-badge>
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between py-2">
          <span>Status</span>
          <b-badge @click="changeStatus" style="cursor: pointer" :variant="detail.status? 'success' : 'warning'">
            {{ detail.status ? 'Enabled' : 'Disabled' }}
          </b-badge>
        </b-list-group-item>
      </b-list-group>
      <h6 class="mt-3">Files uploaded</h6>
      <b-list-group>
        <b-list-group-item v-for="(file) of detail.files" class="d-flex justify-content-between">
          <span>{{ file.comment }}</span>
          <span>{{ file.uploaded|localeDate }}</span>
          <b-badge style="cursor: pointer" :variant="file.key_file === detail.key_file? 'success' : 'warning'"
                   @click="markAsUsed(file)">
            {{ file.key_file === detail.key_file ? 'In Use' : 'Use this' }}
          </b-badge>
        </b-list-group-item>
      </b-list-group>
      <h6 class="mt-3" v-b-toggle.options>Options</h6>
      <b-collapse visible id="options">
        <b-list-group>
          <b-list-group-item v-for="(item, index) of detail.visual_options" class="d-flex justify-content-between py-0">
            <div>{{ 1 + index }}) {{ item.name }}</div>
            <div>{{ ((item.value === true) ? '✓' : item.value) }}</div>
          </b-list-group-item>
        </b-list-group>
      </b-collapse>
    </b-col>
    <b-col lg="6">
      <h6>Edit file notes</h6>
      <form-tuned-file :stock_file="detail.stock_file" ref="edit" @save="load"/>
    </b-col>
  </b-row>
</template>

<script>
import {get, post} from "@/services/api";
import FormTunedFile from "@/views/dashboard/file-server/FormTunedFile";

export default {
  name: "TunedFileDetail",
  components: {FormTunedFile},
  data: () => ({
    id: null,
    detail: null,
    prices: {
      'Stock': 'stockPrice',
      '1': 'stageOnePrice',
      '2': 'stageTwoPrice',
      '2.1': 'stageTwoPlusPrice',
      '3': 'stageThreePrice',
      '4': 'stageFourPrice',
    }
  }),
  methods: {
    load() {
      get(`files/view-tuned/${this.id}`, null, true).then(({data}) => {
        this.detail = data;
        localStorage.setItem('file_project_id', data.stock_file._id);
        this.$nextTick(() => {
          this.$refs.edit.setToEdit(data);
        });
      })
    },
    update() {
      post(`files/update/${this.detail._id}`, this.detail, true)
        .then(() => {

        })
    },
    changeStatus() {
      post(`files/change-tuned-status/${this.detail._id}`, {status: !this.detail.status})
        .then(() => {
          this.detail.status = !this.detail.status
        })
    },
    markAsUsed(file) {
      if (file.key_file !== this.detail.key_file) {
        this.$swal({
          title: 'Are you sure?',
          text: "You are about to mark this file as in use. This will override the current in use file.",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d14343',
          cancelButtonColor: '#000000',
          confirmButtonText: 'Yes, mark it!'
        }).then((result) => {
          if (result.value) {
            post(`files/tuned-file-set-file/${this.detail._id}`, {key_file: file.key_file})
              .then(() => {
                this.detail.key_file = file.key_file
              })
          }
        })

      }
    }
  },
  watch: {
    '$route.params.id'() {
      this.id = this.$route.params.id
      this.load();
    },
  },
  mounted() {
    this.id = this.$route.params.id
    this.load()
  }
}
</script>

<style scoped lang="scss">
td, th {
  padding: 5px;
}
</style>
